import { css } from '@emotion/react'
import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { LayoutDefault } from '../../components/organisms/Layout/LayoutDefault'
import { styleTable } from '../../components/styles/table'

const tableData = [
  {
    head: '1993年 <br class="pc:hidden">4月',
    body: '当社グループの前身となる株式会社ジリオン（現　レッドロック株式会社、2013年11月事業から完全撤退）を設立',
  },
  {
    head: '2001年 <br class="pc:hidden">3月',
    body: '株式会社ジリオンキャリアリンク（現　株式会社キャスティングロード）を設立',
  },
  {
    head: '2013年 <br class="pc:hidden">7月',
    body: '株式会社ＩＣＣＥソフト（現　株式会社ＣＲドットアイ）を子会社化',
  },
  {
    head: '2013年 <br class="pc:hidden">10⽉',
    body: 'ＣＲグループホールディングス株式会社（現　ＣＲＧホールディングス株式会社）を設立<br/>株式会社ＣＲＳサービスを設立<br/>株式会社ジョブスを設立',
  },
  {
    head: '2015年 <br class="pc:hidden">10⽉',
    body: '株式会社プロテクスを設立',
  },
  {
    head: '2018年 <br class="pc:hidden">10⽉',
    body: 'ＣＲＧホールディングス株式会社が東京証券取引所マザーズ（現　グロース）市場に株式を上場',
  },
  {
    head: '2019年 <br class="pc:hidden">10⽉',
    body: '株式会社パレットを設立',
  },
  {
    head: '2021年 <br class="pc:hidden">2⽉',
    body: 'ＣＲＧインベストメント株式会社を設立',
  },
  {
    head: '2022年 <br class="pc:hidden">10⽉',
    body: '株式会社ＣＲＳサービスが株式会社パレットを吸収合併、社名を株式会社パレットに変更',
  },
  {
    head: '2022年 <br class="pc:hidden">12⽉',
    body: '株式会社オシエテを子会社化',
  },
  {
    head: '2024年 <br class="pc:hidden">4⽉',
    body: '株式会社クレイリッシュを子会社化​',
  },
  {
    head: '2024年 <br class="pc:hidden">10⽉',
    body: '株式会社CRドットアイが、株式会社キャスティングロード、株式会社ジョブスを吸収合併し、株式会社ミライルに社名変更​',
  },
]

const Page = () => {
  return (
    <LayoutDefault
      heading="沿革"
      headingEnglish={ENGLISH_TITLE.History}
      BreadcrumbData={[
        { title: '企業情報', url: '/company' },
        { title: '沿革', url: '' },
      ]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <section className="bg-white">
        <div
          css={css`
            padding: 48px 32px;
            @media screen and (max-width: 1199px) {
              padding: 0;
            }
            ${styleTable}
          `}
        >
          <table>
            <tbody>
              {tableData.map((row, i) => (
                <tr key={i}>
                  <th dangerouslySetInnerHTML={{ __html: row.head }} />
                  <td dangerouslySetInnerHTML={{ __html: row.body }} />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </LayoutDefault>
  )
}

export default Page

export const Head = () => <SEO title="沿革" url="/company/history" />
